import * as React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import Wrapper from "../../components/blog/Wrapper"
import Layout from "../../components/layout"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SEO from "../../components/seo"

/*
site {
        siteMetadata {
            title
            description
        }
    }
*/

export const query = graphql
`
query SITE_INDEX_QUERY {
    
    allMdx (
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { frontmatter: { published: { eq: true } } }
    ) {
        nodes {
            id
            frontmatter {
                title
                published
                date(formatString: "MMMM Do, YYYY")
                description
                author
                tags
                preview {
                    childImageSharp {
                        gatsbyImageData(
                            width: 1000
                            placeholder: BLURRED
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                   
                }
            }
            
            fields {
                slug
            }
        }
    }
}
`

const HomePage = ({ data }) => {
    return (
        <Layout theme="white">
            <SEO title="Data science blog" description="A blog about cutting-edge tecnology in data science and statistics"/>
        <Wrapper>
        <dt-article>
        <Container>
            <div className="l-page blog-title">
                <h1>Bytes of thoughts</h1>
                <Ruler />
                <p>A blog about data science, programming and statistics</p>
            </div>
            <div className="posts-list l-page">
            {data.allMdx.nodes.map(({ excerpt, frontmatter, fields }) => (
                <div className="post-preview peer-reviewed">
                <div className="metadata">
                    <div className="publishedDate">{frontmatter.date}</div>
                    <div className="tags">
                        {frontmatter.tags.map((tag) => (
                            <span className="tag">{tag}</span>
                        ))}
                    </div>
                </div>
                
                <StyledLink to={fields.slug}>
                    <div className="thumbnail">
                        <GatsbyImage image={getImage(frontmatter.preview)} />
                    </div>
                    <div className="description">
                        <h2 className="title">{frontmatter.title}</h2>
                        <p className="authors">{frontmatter.author}</p>
                        <p className="abstract">{frontmatter.description}</p>
                    </div>
                </StyledLink>
                </div>
            ))}
            </div>
            </Container>
            </dt-article>
            </Wrapper>
            </Layout>
    )
}

export default HomePage

const Ruler = styled.div`

    width: 20%;
    border-top: 1px solid rgba(0, 0, 0, 0.7);
    height: 5vh;
`
const StyledLink = styled(Link)`

    text-decoration: none;
`

const Container = styled.div`

    div.tags {
        display: flex;
        flex-wrap: wrap;
    }
    
    .blog-title {
        display: flex;
        flex-direction: column;
        margin-top: 6vh;
        margin-bottom: 3vh;
        align-items: center;
        justify-content: center;
        
    } 

    .blog-title h1 {
        font-family: "Inconsolata"
    }
    

    .description h2,
    .description p {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif;
    }

    .description h2 {
        font-weight: 700;
    }

    .posts-list .post-preview .description p.authors {
        font-size: 0.875rem;
        font-weight: 400;
        margin-bottom: 1em;
        color: rgba(0, 0, 0, 0.67);
    }

    h2.post-tag {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        padding-bottom: 12px;
    }

    .posts-list {
        margin-top: 24px;
        margin-bottom: 24px;
    }

    .posts-list .post-preview {
        text-decoration: none;
        overflow: hidden;
        display: block;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding: 24px 0;
    }

    .posts-list .post-preview:last-of-type {
        border-bottom: none;
    }

    .posts-list .post-preview h2 {
        margin: 0 0 6px 0;
        line-height: 1.2em;
        font-style: normal;
        font-size: 24px;
    }

    .posts-list .post-preview p {
        margin: 0 0 12px 0;
        line-height: 1.4em;
        font-size: 16px;
    }

    .posts-list .post-preview .thumbnail {
        box-sizing: border-box;
        margin-bottom: 24px;
        position: relative;
        max-width: 500px;
    }

    .posts-list .post-preview.editorial .thumbnail {
        display: none;
    }

    .posts-list .post-preview img {
        width: 100%;
        display: block;
    }

    .posts-list .metadata {
        font-size: 12px;
        line-height: 1.4em;
        margin-bottom: 18px;
    }

    .metadata>* {
        display: inline-block;
    }

    .metadata .publishedDate {
        margin-right: 1em;
    }

    .metadata .tags {
        margin-top: 1em;
    }

    .tags .tag {
        display: inline;
        color: rgba(0, 0, 0, 0.67);
        padding: 0.3em 0.5em;
        margin: 0;
        font-size: 80%;
        border: 1px solid rgba(0, 0, 0, 0.4);
        border-radius: 3px;
        text-transform: uppercase;
        font-weight: 500;
    }

    .tag.peer-reviewed {
        background-color: #0F2E3DE0;
        color: white;
        border-color: #0F2E3D;
    }

    @media(min-width: 768px) {
        .metadata>* {
            display: block;
        }

        .posts-list .post-preview h2 {
            font-size: 26px;
        }

        .posts-list {
            margin-top: 60px;
        }

        .posts-list .post-preview .thumbnail {
            float: right;
            width: 35%;
            margin-bottom: 0;
        }

        .posts-list .post-preview .description {
            float: left;
            width: 40%;
        }

        .posts-list .post-preview .metadata {
            float: left;
            width: 15%;
            margin-top: 8px;
        }

        .posts-list .post-preview p {
            margin: 0 0 12px 0;
            line-height: 1.5em;
            font-size: 16px;
        }

    }


    
.l-body,
.l-body-outset,
.l-page,
.l-page-outset,
.l-middle,
.l-middle-outset,
dt-article > div,
dt-article > p,
dt-article > h1,
dt-article > h2,
dt-article > h3,
dt-article > h4,
dt-article > figure,
dt-article > table,
dt-article > ol,
dt-article > ul,
dt-article > dt-byline,
dt-article > dt-math,
dt-article > dt-code,
dt-article section > div,
dt-article section > p,
dt-article section > h1,
dt-article section > h2,
dt-article section > h3,
dt-article section > h4,
dt-article section > figure,
dt-article section > table,
dt-article section > ol,
dt-article section > ul,
dt-article section > dt-byline,
dt-article section > dt-code {
  width: auto;
  margin-left: 24px;
  margin-right: 24px;
  box-sizing: border-box;
}

@media(min-width: 768px) {
  .l-body,
  .l-body-outset,
  .l-page,
  .l-page-outset,
  .l-middle,
  .l-middle-outset,
  dt-article > div,
  dt-article > p,
  dt-article > h1,
  dt-article > h2,
  dt-article > h3,
  dt-article > h4,
  dt-article > figure,
  dt-article > table,
  dt-article > ol,
  dt-article > ul,
  dt-article > dt-byline,
  dt-article > dt-math,
  dt-article > dt-code,
  dt-article section > div,
  dt-article section > p,
  dt-article section > h1,
  dt-article section > h2,
  dt-article section > h3,
  dt-article section > h4,
  dt-article section > figure,
  dt-article section > table,
  dt-article section > ol,
  dt-article section > ul,
  dt-article section > dt-byline,
  dt-article section > dt-code {
    margin-left: 72px;
    margin-right: 72px;
  }
}

@media(min-width: 1080px) {
  .l-body,
  dt-article > div,
  dt-article > p,
  dt-article > h2,
  dt-article > h3,
  dt-article > h4,
  dt-article > figure,
  dt-article > table,
  dt-article > ol,
  dt-article > ul,
  dt-article > dt-byline,
  dt-article > dt-math,
  dt-article > dt-code,
  dt-article section > div,
  dt-article section > p,
  dt-article section > h2,
  dt-article section > h3,
  dt-article section > h4,
  dt-article section > figure,
  dt-article section > table,
  dt-article section > ol,
  dt-article section > ul,
  dt-article section > dt-byline,
  dt-article section > dt-code {
    margin-left: calc(50% - 984px / 2);
    width: 648px;
  }
  .l-body-outset,
  dt-article .l-body-outset {
    margin-left: calc(50% - 984px / 2 - 96px/2);
    width: calc(648px + 96px);
  }
  .l-middle,
  dt-article .l-middle {
    width: 816px;
    margin-left: calc(50% - 984px / 2);
    margin-right: auto;
  }
  .l-middle-outset,
  dt-article .l-middle-outset {
    width: calc(816px + 96px);
    margin-left: calc(50% - 984px / 2 - 48px);
    margin-right: auto;
  }

  .l-page-outset,
  dt-article .l-page-outset,
  dt-article.centered .l-page-outset {
    width: 1080px;
    margin-left: auto;
    margin-right: auto;
  }

  .l-screen,
  dt-article .l-screen,
  dt-article.centered .l-screen {
    margin-left: auto;
    margin-right: auto;
    width: auto;
  }
  .l-screen-inset,
  dt-article .l-screen-inset,
  dt-article.centered .l-screen-inset {
    margin-left: 24px;
    margin-right: 24px;
    width: auto;
  }
  .l-gutter,
  dt-article .l-gutter {
    clear: both;
    float: right;
    margin-top: 0;
    margin-left: 24px;
    margin-right: calc((100vw - 984px) / 2 + 168px);
    width: calc((984px - 648px) / 2 - 24px);
  }

  /* Side */
  .side.l-body,
  dt-article .side.l-body {
    clear: both;
    float: right;
    margin-top: 0;
    margin-left: 48px;
    margin-right: calc((100vw - 984px + 648px) / 2);
    width: calc(648px / 2 - 24px - 84px);
  }
  .side.l-body-outset,
  dt-article .side.l-body-outset {
    clear: both;
    float: right;
    margin-top: 0;
    margin-left: 48px;
    margin-right: calc((100vw - 984px + 648px - 48px) / 2);
    width: calc(648px / 2 - 48px + 24px);
  }
  .side.l-middle,
  dt-article .side.l-middle {
    clear: both;
    float: right;
    width: calc(456px - 84px);
    margin-left: 48px;
    margin-right: calc((100vw - 984px) / 2 + 168px);
  }
  .side.l-middle-outset,
  dt-article .side.l-middle-outset {
    clear: both;
    float: right;
    width: 456px;
    margin-left: 48px;
    margin-right: calc((100vw - 984px) / 2 + 168px);
  }
  .side.l-page,
  dt-article .side.l-page {
    clear: both;
    float: right;
    margin-left: 48px;
    width: calc(624px - 84px);
    margin-right: calc((100vw - 984px) / 2);
  }
  .side.l-page-outset,
  dt-article .side.l-page-outset {
    clear: both;
    float: right;
    width: 624px;
    margin-right: calc((100vw - 984px) / 2);
  }
}


.posts-list {

    width: auto;
    margin-left: 24px;
    margin-right: 24px;
    box-sizing: border-box;
}


`